/*
 * This file contains the code used to create the charts on the patient and therapist dashboard.
 * The top part is an extension used to display a message when no data is available
 * (had to be present in the same file for it to work due to import issues.)
 */

// ('use strict');

// (function () {
//     const { Chart } = window;
//     const { helpers } = Chart;

//     Chart.defaults.global.emptyOverlay = {
//         enabled: true,
//         message: trans.forms['no-data-available'],
//         fillStyle: 'rgba(100,100,100,0.3)',
//         fontColor: 'rgba(100,100,100,1.0)',
//         fontStroke: 'rgba(255,255,255,0.6)',
//         fontStrokeWidth: '3',
//         fontSize: 'auto'
//     };

//     /**
//      * Checks if the chart is currently empty
//      * @param datasets
//      * @param model
//      * @returns {boolean}
//      */
//     function isChartEmpty(datasets, model) {
//         // Check for non existent datasets
//         if (!datasets || datasets.length < 1) {
//             model.isEmpty = true;
//         }

//         // Filter down the datasets to determine if they contain non zero values
//         const filtered = datasets
//             .map(element => {
//                 const fltr = element.data.filter(ele =>
//                     // Support data expressed as x,y coordinates as well as y only values
//                     ele.x ? ele.x >= 0 : ele >= 0
//                 );
//                 return fltr.length > 0;
//             })
//             .filter(element => element);

//         return (model.isEmpty = filtered.length < 1);
//     }

//     Chart.EmptyOverlay = Chart.Element.extend({
//         position: 'chartArea',

//         initialize(config) {
//             helpers.extend(this, config);
//         },

//         // Shared Methods
//         isHorizontal() {
//             return this.options.position === 'top' || this.options.position === 'bottom';
//         },

//         // no-op
//         update() {},

//         // Actually draw the legend on the canvas
//         draw() {
//             const me = this;
//             const { ctx } = me;

//             const globalDefault = Chart.defaults.global;
//             const emptyOpts = me.options;
//             const { chartArea } = me.chart;
//             const x = chartArea.left;
//             const y = chartArea.top;
//             const width = chartArea.right - chartArea.left;
//             const height = chartArea.bottom - chartArea.top;
//             const textX = x / 2 + width / 2;
//             const textY = y + height / 2;
//             const itemOrDefault = helpers.getValueOrDefault;
//             const { message } = emptyOpts;
//             const fontSizeOpt = itemOrDefault(emptyOpts.fontSize, globalDefault.defaultFontSize);
//             const fontSize = fontSizeOpt === 'auto' ? Math.sqrt(width) : fontSizeOpt;
//             const fontStyle = itemOrDefault(emptyOpts.fontStyle, globalDefault.defaultFontStyle);
//             const fontFamily = itemOrDefault(emptyOpts.fontFamily, globalDefault.defaultFontFamily);
//             const labelFont = helpers.fontString(fontSize, fontStyle, fontFamily);

//             ctx.fillStyle = itemOrDefault(emptyOpts.fillStyle, globalDefault.defaultColor);
//             ctx.fillRect(x, y, width, height);

//             ctx.textAlign = 'center';
//             ctx.textBaseline = 'middle';
//             ctx.font = labelFont;
//             ctx.lineWidth = emptyOpts.fontStrokeWidth;
//             ctx.miterLimit = 2;
//             ctx.strokeStyle = emptyOpts.fontStroke;
//             ctx.strokeText(message, textX, textY, width);

//             ctx.fillStyle = itemOrDefault(emptyOpts.fontColor, globalDefault.defaultFontColor);
//             ctx.fillText(message, textX, textY, width);
//         }
//     });

//     function createNewEmptyOverlay(chartInstance, emptyOpts) {
//         const emptyOverlay = new Chart.EmptyOverlay({
//             ctx: chartInstance.chart.ctx,
//             options: emptyOpts,
//             originalConfig: chartInstance.config,
//             chart: chartInstance,
//             isEmpty: false
//         });
//         chartInstance.emptyOverlay = emptyOverlay;
//     }

//     // Register the emptyOverlay plugin
//     Chart.plugins.register({
//         beforeInit(chartInstance) {
//             // Merge config
//             let emptyOpts = chartInstance.options.emptyOverlay || {};
//             emptyOpts = helpers.configMerge(Chart.defaults.global.emptyOverlay, emptyOpts);

//             // Add config and create object
//             createNewEmptyOverlay(chartInstance, emptyOpts);
//         },

//         beforeUpdate(chartInstance) {
//             const empty = isChartEmpty(chartInstance.config.data.datasets, chartInstance.emptyOverlay);
//             if (empty) {
//                 chartInstance.config.data.datasets = [];
//                 chartInstance.config.data.labels = [];
//             }
//         },

//         afterDatasetsDraw(chartInstance) {
//             // Merge config
//             let emptyOpts = chartInstance.options.emptyOverlay || {};
//             emptyOpts = helpers.configMerge(Chart.defaults.global.emptyOverlay, emptyOpts);

//             // Add config and create object if needed
//             if (!chartInstance.emptyOverlay) {
//                 createNewEmptyOverlay(chartInstance, emptyOpts);
//             } else {
//                 chartInstance.emptyOverlay.options = emptyOpts;
//             }

//             // Check if this is enabled and chart is empty
//             if (emptyOpts.enabled && chartInstance.emptyOverlay.isEmpty) {
//                 // Check if it's already rendered
//                 if (!chartInstance.emptyOverlayBox) {
//                     chartInstance.emptyOverlayBox = true;
//                     Chart.layoutService.addBox(chartInstance, chartInstance.emptyOverlay);
//                 }
//             } else if (chartInstance.emptyOverlayBox) {
//                 Chart.layoutService.removeBox(chartInstance, chartInstance.emptyOverlay);
//                 delete chartInstance.emptyOverlayBox;
//             }
//         }
//     });
// })();

/* HEAD:
 * This files contains all logic and function to build the charts necessary for the patient and therapist pages.
 */
function build_charts(
    $days = 7,
    wrapper_id = 'chart_wrapper',
    chart1_id = 'my_chart_ease_simple',
    chart2_id = 'my_chart_ease_detail',
    chart3_id = 'my_chart_discipline_detail',
    chart4_id = 'my_chart_discipline_simple',
    chart_data_local = chart_data
) {
    const ctx = document.getElementById(chart1_id);
    const ctx2 = document.getElementById(chart2_id);
    const ctx3 = document.getElementById(chart3_id);
    const ctx4 = document.getElementById(chart4_id);
    Chart.defaults.defaultFontSize = 16;

    // Defines the chart colors array that is used to define the colors of the lines and exercises.
    const chartColors = [
        'rgb(255, 159, 64, 0.5)',
        'rgb(255, 205, 86, 0.5)',
        'rgb(75, 192, 192, 0.5)',
        'rgb(54, 162, 235, 0.5)',
        'rgb(153, 102, 255, 0.5)',
        'rgb(201, 203, 207, 0.5)'
    ];

    // Same as above only for the borders instead of the line or bar color itself.
    const chartBorders = [
        'rgb(255, 159, 64)', // orange
        'rgb(255, 205, 86)', // yellow
        'rgb(75, 192, 192)', // green
        'rgb(54, 162, 235)', // blue
        'rgb(153, 102, 255)', // purple
        'rgb(201, 203, 207)' // grey
    ];

    let data = [];
    let labels = Object.keys(chart_data_local['list_ease']).slice(-$days);

    /* Builds an array that contains the line data for each psk.
     */
    var i = 0;
    for (var key in chart_data_local.psk) {
        if (chart_data_local.psk.hasOwnProperty(key)) {

            /* First add the right indices to the psk array to work with the labels */
            const psks = [];
            const p = Object.entries(chart_data_local.psk[key]).slice(-$days);
            for (const [key, value] of p) {
                var index = labels.indexOf(key);
                if (index == -1) continue;
                psks[index] = value;
            }

            data.push({
                label: key,
                data: psks,
                backgroundColor: chartColors[i % chartColors.length],
                borderColor: chartBorders[i % chartBorders.length],
                borderWidth: 3,
                fill: false
            });
        }
        i++;
    }

    /* Extracts the "Gemak" or ease graph from the given data and pushes it into the data array (where the PSK and the other graph data is also saved)
     * First add the right indices to the ease array to work with the labels
     */
    const ease = [];
    const e = Object.entries(chart_data_local['list_ease']).slice(-$days);
    for (const [key, value] of e) {
        var index = labels.indexOf(key);
        if (index == -1) continue;
        ease[index] = value;
    }

    data.push({
        label: trans.forms.ease,
        data: ease,
        backgroundColor: chartColors[i % chartColors.length],
        borderColor: chartBorders[i % chartBorders.length],
        borderWidth: 3,
        fill: false
    });
    i++;

    /* Same thing as above but with average pain.
     * Commented out for beta, possibly going to be a seperate graph
     */
    // var temp = {
    //     label: trans.forms['avg-pain'],
    //     data: Object.values(chart_data_local["overall_pain"]).slice(-$days),
    //     backgroundColor: chartColors[i % chartColors.length],
    //     borderColor: chartBorders[i % chartBorders.length],
    //     borderWidth: 3,
    //     fill: false,
    // }
    // data.push(temp)
    // i++;

    // const tooltip =
    /*
     * Defines the line chart and sets the options needed for it.
     */
    window.myChart = new Chart(ctx, {
        type: 'line',
        data: {
            labels: labels,
            datasets: data,
            fill: false
        },
        options: {
            emptyOverlay: {
                // enabled by default
                // fillStyle: 'rgba(255,0,0,0.2)',     // Change the color of the overlay to red with a 40% alpha
                fontColor: 'rgba(0,0,0,1.0)', // Change the text color to white
                fontStrokeWidth: 0 // Hide the stroke around the text
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            suggestedMin: 0,
                            suggestedMax: 10
                        },
                        scaleLabel: {
                            display: true,
                            labelString: trans.forms['ease-scale']
                        }
                    }
                ],
                xAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: jsLocalArguments(trans.forms['past-n-days'], { n: $days })
                        }
                    }
                ]
            }
        }
    });

    /* Labels used for chart 2 and 4. */
    // var labels = chart_data.days.slice(-$days);
    
    labels = Object.keys(chart_data_local['overall_pain']).slice(-$days);

    /*
     * The data for the bar charts is extracted and put into the data_bar object here.
     */
    var data_bar = {};
    const reasons = {};
    var i = 0;
    var { days } = chart_data_local;
    for (var key in chart_data_local['exercise_pain']) {
        if (chart_data_local['exercise_pain'].hasOwnProperty(key)) {
            let counter = 0;
            var title;
            for (var item in chart_data_local['exercise_pain'][key]) {
                if (chart_data_local['exercise_pain'][key].hasOwnProperty(item)) {
                    var { title } = chart_data_local['exercise_pain'][key][item];
                    var index = labels.indexOf(key);
                    var to_insert = chart_data_local['exercise_pain'][key][item].pain;
                    reasons[title] = chart_data_local['exercise_pain'][key][item].reason;
                    if (!data_bar.hasOwnProperty(title)) {
                        data_bar[title] = new Array($days);
                    }
                    data_bar[title][index] = to_insert;
                }
            }
            counter++;
        }
    }

    /*
     * We then loop over the data_bar object, extract the data and build the graph.
     * This has to be done this way because we receive the data per day, and we want the data per exercise, or at least that is what chartjs wants.
     */
    i = 0;
    data = [];
    for (var key in data_bar) {
        let backgroundColor = chartColors[i % chartColors.length];
        let borderColor = chartBorders[i % chartBorders.length];

        if (reasons[key]) {
            backgroundColor = 'rgb(255, 99, 132, 0.5)';
            borderColor = 'rgb(255, 99, 132)';
        }
        data.push({
            label: key,
            data: data_bar[key].slice(-$days),
            backgroundColor,
            borderColor,
            borderWidth: 3,
            fill: false
        });
        i++;
    }

    /*
     * Creates the bar chart or detailed chart.
     */
    window.myChart2 = new Chart(ctx2, {
        type: 'bar',
        data: {
            labels: labels,
            datasets: data
        },
        options: {
            tooltips: {
                // Disable the on-canvas tooltip
                enabled: false,
                custom(tooltipModel) {
                    // Tooltip Element
                    let tooltipEl = document.getElementById('chartjs-tooltip');

                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjs-tooltip';
                        tooltipEl.innerHTML = '<table></table>';
                        document.body.appendChild(tooltipEl);
                    }

                    // Hide if no tooltip
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }

                    // Set caret Position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }

                    function getBody(bodyItem) {
                        return bodyItem.lines;
                    }

                    // Set Text
                    if (tooltipModel.body) {
                        const titleLines = tooltipModel.title || [];
                        const bodyLines = tooltipModel.body.map(getBody);

                        let innerHtml = '<thead>';

                        const elems = [];
                        titleLines.forEach(title => {
                            innerHtml += `<tr><th>${title}</th></tr>`;
                        });
                        innerHtml += '</thead><tbody>';

                        let clone = '';
                        bodyLines.forEach((body, i) => {
                            // Edit the id because spaces are not allowed in ID's
                            const split = String(body).split(':');
                            const title = split[0];
                            const temp = split[0].replace(/[\s/:;]+/g, '');

                            // Corresponds to key in zeroData
                            const name = split[0];
                            const { index } = tooltipModel.dataPoints[0];
                            const temp1 = [];

                            // console.log("/storage/storage/thumb/" + chart_data["exercise_mapping"][title]);

                            const id = `exercise-${temp}`;

                            clone = crel('img', {
                                class: 'card-img-top calender-img',
                                css: 'width: "100px"; height: "100px"',
                                src: `/storage/${chart_data['exercise_mapping'][title]}`
                            });
                            if (reasons[title]) {
                                split.push(`<b><br>${trans.forms.reason}${reasons[title]}</b>`);
                            }
                            const colors = tooltipModel.labelColors[i];
                            let style = `background:${colors.backgroundColor}`;
                            style += `; border-color:${colors.borderColor}`;
                            style += '; border-width: 2px';
                            const span = `<span style="${style}"></span>`;

                            body = split.join(' ');
                            innerHtml += `<tr><td>${span}${body}</td></tr>`;
                        });
                        innerHtml += '</tbody>';

                        const tableRoot = tooltipEl.querySelector('table');
                        tableRoot.innerHTML = innerHtml;

                        // clone.css("width", "100px");
                        // clone.css("height", "100px");
                        $(tableRoot).prepend(clone);
                    }

                    // `this` will be the overall tooltip
                    const position = this._chart.canvas.getBoundingClientRect();

                    // Display, position, and set styles for font
                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.position = 'absolute';
                    tooltipEl.style.color = 'white';
                    tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`;
                    tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`;
                    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                    tooltipEl.style.fontSize = `${tooltipModel.bodyFontSize}px`;
                    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                    tooltipEl.style.padding = `${tooltipModel.yPadding}px ${tooltipModel.xPadding}px`;
                    tooltipEl.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
                    tooltipEl.style.width = '200px';
                    tooltipEl.style.maxWidth = '200px';
                    tooltipEl.style.borderRadius = '3px';
                    tooltipEl.style.pointerEvents = 'none';
                }
            },
            emptyOverlay: {
                // enabled by default
                // fillStyle: 'rgba(255,0,0,0.4)',     // Change the color of the overlay to red with a 40% alpha
                fontColor: 'rgba(0, 0, 0, 1.0)', // Change the text color to white
                fontStrokeWidth: 0 // Hide the stroke around the text
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            suggestedMin: 0,
                            suggestedMax: 10
                        },
                        scaleLabel: {
                            display: true,
                            labelString: trans.forms['pain-scale']
                        }
                    }
                ],
                xAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: jsLocalArguments(trans.forms['past-n-days'], { n: $days })
                        }
                    }
                ]
            }
        }
    });

    if (!chart_data_local.discipline) {
        return;
    }

    // Prepare data
    const line_discipline = JSON.parse(JSON.stringify(chart_data_local.discipline));

    Object.keys(line_discipline).map((key, index) => {
        const object = line_discipline[key];
        line_discipline[key] = object.discipline * 100;
    });

    // console.log(Object.values(line_discipline).slice(-$days));

    /* Builds an array that contains the line data for each psk.
     */

    /*
     *  Create normal chart for discipline
     */
    window.myChart3 = new Chart(ctx3, {
        type: 'line',
        data: {
            labels: labels,
            datasets: [
                {
                    label: 'Discipline',
                    data: Object.values(line_discipline).slice(-$days),
                    backgroundColor: chartColors[i % chartColors.length],
                    borderColor: chartBorders[i % chartBorders.length],
                    borderWidth: 3,
                    fill: false
                }
            ],
            fill: false
        },
        options: {
            emptyOverlay: {
                // enabled by default
                // fillStyle: 'rgba(255,0,0,0.2)',     // Change the color of the overlay to red with a 40% alpha
                fontColor: 'rgba(0,0,0,1.0)', // Change the text color to white
                fontStrokeWidth: 0 // Hide the stroke around the text
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            suggestedMin: 0,
                            suggestedMax: 100
                        },
                        scaleLabel: {
                            display: true,
                            labelString: trans.forms['discipline-labels']
                        }
                    }
                ],
                xAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: jsLocalArguments(trans.forms['past-n-days'], { n: $days })
                        }
                    }
                ]
            }
        }
    });

    /*
     * The data for the bar charts is extracted and put into the data_bar object here.
     */
    var data_bar = {};
    var i = 0;
    var { days } = chart_data_local;
    for (var key in chart_data_local.discipline) {
        if (chart_data_local.discipline.hasOwnProperty(key)) {
            for (var item in chart_data_local.discipline[key].exercises) {
                if (chart_data_local.discipline[key].exercises.hasOwnProperty(item)) {
                    var { title } = chart_data_local.discipline[key].exercises[item].exercises;
                    var index = labels.indexOf(key);
                    var to_insert =
                        Math.round(chart_data_local.discipline[key].exercises[item].discipline * 100 * 10) / 10;
                    if (!data_bar.hasOwnProperty(title)) {
                        data_bar[title] = new Array($days);
                    }
                    data_bar[title][index] = to_insert;
                }
            }
        }
    }

    /*
     * We then loop over the data_bar object, extract the data and build the graph.
     * This has to be done this way because we receive the data per day, and we want the data per exercise, or at least that is what chartjs wants.
     * ChartJS doesn't show a bar plot for a zero value. To indicate a discipline of zero the following was implemented:
     * - zeroData object contains for each bar (with the exercise name as key) the array that is used in the chart itself however with the zero data retained.
     * - Then the zero values are changed to one in the chartdata so that the graph is visible.
     * - In the tooltip we use the zeroData again to display the correct value.
     */
    i = 0;
    data = [];
    const zeroData = {};
    for (var key in data_bar) {
        const d = data_bar[key].slice(-$days);
        zeroData[key] = [];
        for (let i = 0; i < d.length; i++) {
            zeroData[key].push(d[i]);
            if (d[i] == 0) {
                d[i] = 1;
            }
        }

        let backgroundColor = chartColors[i % chartColors.length];
        let borderColor = chartBorders[i % chartBorders.length];

        if (reasons[key]) {
            backgroundColor = 'rgb(255, 99, 132, 0.5)';
            borderColor = 'rgb(255, 99, 132)';
        }

        data.push({
            label: key,
            data: d,
            backgroundColor,
            borderColor,
            borderWidth: 3,
            fill: false
        });
        i++;
    }

    /*
     *  Bar chart for discipline
     */
    window.myChart4 = new Chart(ctx4, {
        type: 'bar',
        data: {
            labels: labels,
            datasets: data
        },
        options: {
            tooltips: {
                // Disable the on-canvas tooltip
                enabled: false,
                custom(tooltipModel) {
                    // Tooltip Element
                    let tooltipEl = document.getElementById('chartjs-tooltip');

                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjs-tooltip';
                        tooltipEl.innerHTML = '<table></table>';
                        document.body.appendChild(tooltipEl);
                    }

                    // Hide if no tooltip
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }

                    // Set caret Position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }

                    function getBody(bodyItem) {
                        return bodyItem.lines;
                    }

                    // Set Text
                    if (tooltipModel.body) {
                        const titleLines = tooltipModel.title || [];
                        const bodyLines = tooltipModel.body.map(getBody);

                        let innerHtml = '<thead>';

                        const elems = [];
                        titleLines.forEach(title => {
                            innerHtml += `<tr><th>${title}</th></tr>`;
                        });

                        // console.log(innerHtml);
                        innerHtml += '</thead><tbody>';

                        let clone = '';
                        bodyLines.forEach((body, i) => {
                            // Edit the id because spaces are not allowed in ID's
                            const split = String(body).split(':');
                            const title = split[0];
                            const temp = split[0].replace(/[\s/:;]+/g, '');

                            // Corresponds to key in zeroData
                            const name = split[0];
                            const { index } = tooltipModel.dataPoints[0];
                            split[1] = ` ${zeroData[name][index]}`;

                            const id = `exercise-${temp}`;
                            clone = crel('img', {
                                class: 'card-img-top calender-img',
                                css: 'width: "100px"; height: "100px"',
                                src: `/storage/${chart_data['exercise_mapping'][title]}`
                            });
                            const colors = tooltipModel.labelColors[i];
                            if (reasons[title]) {
                                split.push(`<br><b>${trans.forms.reason}${reasons[title]}</b>`);
                            }
                            let style = `background:${colors.backgroundColor}`;
                            style += `; border-color:${colors.borderColor}`;
                            style += '; border-width: 2px';
                            const span = `<span style="${style}"></span>`;

                            body = split.join(' ');
                            innerHtml += `<tr><td>${span}${body}</td></tr>`;
                        });
                        innerHtml += '</tbody>';

                        const tableRoot = tooltipEl.querySelector('table');
                        tableRoot.innerHTML = innerHtml;

                        // clone.css("width", "100px");
                        // clone.css("height", "100px");
                        $(tableRoot).prepend(clone);
                    }

                    // `this` will be the overall tooltip
                    const position = this._chart.canvas.getBoundingClientRect();

                    // Display, position, and set styles for font
                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.position = 'absolute';
                    tooltipEl.style.color = 'white';
                    tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`;
                    tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`;
                    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                    tooltipEl.style.fontSize = `${tooltipModel.bodyFontSize}px`;
                    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                    tooltipEl.style.padding = `${tooltipModel.yPadding}px ${tooltipModel.xPadding}px`;
                    tooltipEl.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
                    tooltipEl.style.width = '200px';
                    tooltipEl.style.maxWidth = '200px';
                    tooltipEl.style.borderRadius = '3px';
                    tooltipEl.style.pointerEvents = 'none';
                }
            },
            emptyOverlay: {
                // enabled by default
                // fillStyle: 'rgba(255,0,0,0.4)',     // Change the color of the overlay to red with a 40% alpha
                fontColor: 'rgba(0, 0, 0, 1.0)', // Change the text color to white
                fontStrokeWidth: 0 // Hide the stroke around the text
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            suggestedMin: 0,
                            suggestedMax: 100
                        },
                        scaleLabel: {
                            display: true,
                            labelString: trans.forms['discipline-labels']
                        }
                    }
                ],
                xAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: jsLocalArguments(trans.forms['past-n-days'], { n: $days })
                        }
                    }
                ]
            }
        }
    });
}

$(document).ready(() => {
    if (window.loadCharts) {
        build_charts();
    }
});
window.build_charts = build_charts;

/* Destroys all old versions of the charts
 * https://stackoverflow.com/questions/43490743/chartjs-mouse-hover-bug-showing-previous-charts
 */
function destroyCharts() {
    myChart.destroy();
    myChart2.destroy();
    myChart3.destroy();
    myChart4.destroy();
}
window.destroyCharts = destroyCharts;
